import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import KeyFeatures from 'containers/AppMinimal/KeyFeatures';
import ChooseUs from 'containers/AppMinimal/ChooseUs';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';

const Terms = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Mobidetect | Terms & Conditions"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1>Terms & Conditions</h1>
                            <h5 className="c6" id="h.yem8729qyksq"><span className="c0">1. Ownership</span></h5>
                            <p className="c2"><span>In compliance with Law 34/2002 on Information Society Services and Electronic Commerce (LSSICE), we inform that this site is property and is operated by Tecnogesti&oacute;n y servicios en red SL, a Spanish trading company with registered address at </span><span>Crtra de Tarragona s/n 43887 Nulles (Tarragona</span><span
                                className="c5">)</span><span className="c1">, Spain.</span></p>
                            <p className="c2"><span>Tax identification number: </span><span>B05432182</span></p>
                            <p className="c2"><span>E-mail address: </span><span
                                className="c8 c9">info@mobidetect.com</span></p><h5 className="c6"
                                                                                    id="h.i6ey4ezcil7r">
                            <span className="c0">2. Acceptance</span></h5>
                            <p className="c2"><span className="c1">This website and tracking service by SMS called Mobidetect (hereinafter the Service) is available to any user, for their own consumption, and subject to the terms and conditions: these Terms of Use and Contract (hereinafter TUC), our Privacy Policy and Cookies Policy.</span>
                            </p>
                            <p className="c2"><span className="c1">When you use the Service, you accept these TUC and the other terms and conditions.</span>
                            </p>
                            <p className="c2"><span className="c1">With it, you agree to not use this site for illegal purposes.</span>
                            </p><h5 className="c6"
                                    id="h.i4nzt5gmg6n3">
                            <span className="c0">3. Description of the Service</span></h5>
                            <p className="c2"><span className="c1">Through the Service, Mobidetect allows to track the current position of a person that has consented to be tracked. The Service allows an SMS to be sent to the recipient to tell them that someone wants to know their location. If the recipient of the message expressly accepts, the recipient&rsquo;s geoposition at that specific moment is shared with the requesting party.</span>
                            </p>
                            <p className="c2"><span className="c1">In no case shall the geolocation go beyond that specific moment in which it has been accepted.</span>
                            </p>
                            <p className="c2"><span className="c3">The monthly payment of the service does not guarantee that the person you are looking for can be tracked, the means to achieve this shall only be provided. But if the person is missing, with the mobile phone stolen, turned off or they do not accept the link we send by SMS, we will not be able to get their GPS position.</span>
                            </p><h5 className="c6" id="h.vyf6pnqd1emc"><span className="c0">4. External links</span>
                        </h5>
                            <p className="c2"><span className="c1">You may be sent to other websites through links from the Service.</span>
                            </p>
                            <p className="c2"><span className="c1">However, Mobidetect does not control these sites or their content, that are in fact subject to their own terms and conditions. Therefore, Mobidetect is NOT responsible for the quality, truthfulness or accuracy of these sites.</span>
                            </p><h5 className="c6" id="h.xeok42jy4ey3"><span className="c0">5. Age</span></h5>
                            <p className="c2"><span className="c1">With regards to the use of the Service, you state that you are of legal age and that you have the legal capacity necessary to be bound by this agreement and to use it in accordance with its terms and conditions, which you fully understand and acknowledge.</span>
                            </p>
                            <p className="c2"><span className="c1">You state that all the information you provide to access the Service, before and during its usage, is true, complete and accurate.</span>
                            </p><h5 className="c6" id="h.ayom4ndkclr7"><span className="c0">6. Intellectual and industrial property</span>
                        </h5>
                            <p className="c2"><span className="c1">The Service&rsquo;s content and information (among others, data, text, audio, image and computer code), as well as the infrastructure used to provide such content and information, is property of Mobidetect or it has the corresponding authorisations for its use.</span>
                            </p>
                            <p className="c2"><span className="c1">Also, the modification, reproduction, duplication, copy, distribution, sale, resale and other forms of exploitation for commercial or equivalent purposes of the Service is strictly prohibited.</span>
                            </p>
                            <p className="c2"><span
                                className="c1">For any other use of the Service&rsquo;s content our prior written consent is required.</span>
                            </p><h5
                            className="c6" id="h.7mjrhw1218y"><span className="c0">7. User Content</span></h5>
                            <p className="c2"><span className="c1">You may contribute to the Service by sending us messages to our email address or through the contact form (hereinafter &ldquo;Content&rdquo;).</span>
                            </p>
                            <p className="c2"><span className="c1">We can use your Content in different ways, such as: show it on the website, reformat it, translate it to other languages, edit it to provide clarity, correct errors, promote it or distribute it.</span>
                            </p>
                            <p className="c2"><span className="c1">Therefore, by submitting Content to us you grant Mobidetect worldwide, non-exclusive royalty-free licence, until the content is removed, transferable and sub-licensable on that Content.</span>
                            </p>
                            <p className="c2"><span className="c1">That means that the content remains yours, but Mobidetect, due to this license of use, can: a) use, reproduce, modify, adapt, translate, distribute and publish the Content, create derivative works from it, show it and display it around the world, by any known means and for any legitimate purpose; and b) use the name you send with regard to this Content.</span>
                            </p>
                            <p className="c2"><span className="c1">However, Mobidetect reserves the right to not publish content or information that is false, illegal, misleading or contrary to the rights of third parties.</span>
                            </p><h5 className="c6" id="h.imnsrwbf4suz"><span className="c0">8. Price and taxes</span>
                        </h5>
                            <p className="c2"><span className="c1">The prices of the plans offered by Mobidetect are shown in euros (&euro;) and include the applicable value added tax (VAT) to these services in Spain (21%).</span>
                            </p>
                            <p className="c2"><span className="c1">If the client&rsquo;s place of residence or address is in another member state of the European Union or a non-EU state, the plan&rsquo;s price may suffer modifications if the applicable tax rate of different.</span>
                            </p><h5 className="c6" id="h.rjp78nuh0eb6"><span className="c0">9. Payment methods</span>
                        </h5>
                            <p className="c2"><span
                                className="c1">The payment of the contracted Service can be made:</span></p>
                            <p className="c2"><span className="c1">- By VISA or MasterCard credit or debit card, the amount being charged when purchasing the products.</span>
                            </p>
                            <p className="c2"><span className="c1">In this regard, Mobidetect informs credit and debit card holders that they are responsible for transactions on the online store. These are made on a secure page, using SSL technology to ensure the security in the transmission of data.</span>
                            </p><h5 className="c6" id="h.atd7kh9x56a"><span className="c0">10. Validity of offers</span>
                        </h5>
                            <p className="c2"><span className="c1">The Service offered, and the prices of any plans or types, shall be available to purchase while they are accessible through this website.</span>
                            </p>
                            <p className="c2"><span className="c1">In all cases, Mobidetect reserves the right to make modifications to the Service that it considers appropriate, being able to update products and services according to the market.</span>
                            </p>
                            <p className="c2"><span className="c1">With regards to the prices that appear on the website, Mobidetect reserves the right to change these without prior warning.</span>
                            </p>
                            <p className="c2"><span className="c1">We inform you that despite the updates made to the prices of the Service, these may contain errors. We shall promptly correct all the errors that appear, but they shall not be binding on the Service.</span>
                            </p><h5 className="c6" id="h.mvmre1259qsx"><span className="c0">11. Guarantees</span></h5>
                            <p className="c2"><span
                                className="c1">All rights that the laws in force guarantee you as a consumer and user are guaranteed.</span>
                            </p>
                            <p className="c2"><span className="c1">In any case, while Mobidetect shall make all reasonable commercial efforts to provide the Service, you understand that: a) the user&rsquo;s geolocation requires the express consent of the recipient and; b) that even with their consent, the state of technology does not guarantee that their location is always obtained or that it can be done with sufficient precision.</span>
                            </p>
                            <p className="c2"><span className="c1">In any case, and as far as the law allows, Mobidetect reserves the right to reject, suspend or cancel the provision of the Service at its sole discretion.</span>
                            </p><h5 className="c6" id="h.wv7l8n42y30g"><span className="c0">12. Responsibility</span>
                        </h5>
                            <p className="c2"><span className="c1">To use the Service, you confirm that: a) you have lawfully obtained the telephone number that you want to track and with its owner&rsquo;s prior consent and; b) that you do not intend to use the Service for any unlawful purpose.</span>
                            </p>
                            <p className="c2"><span className="c1">On the other hand, to the extent permitted by the applicable law, Mobidetect shall not be responsible for any loss of data or programmes as a result of using the Service.</span>
                            </p><h5 className="c6" id="h.8yh1p4sf83j1"><span className="c0">13. Security</span></h5>
                            <p className="c2"><span className="c1">On the other hand, Mobidetect ensures the security of the Service in accordance with current technological knowledge.</span>
                            </p>
                            <p className="c2"><span className="c1">However, Mobidetect cannot ensure the complete security of the Service, although it agrees to remedy and implement the appropriate corrective measures to correct any possible security breach as soon as possible.</span>
                            </p>
                            <p className="c2"><span className="c1">The user agrees to notify the Service, immediately and via email to info@mobidetect.com, of any loss of information, access or unauthorised use by third parties or any situation that may result in the identity theft of other users.</span>
                            </p>
                            <p className="c2"><span className="c1">Mobidetect shall not be responsible for damages that may occur when using the Service if it is as a result of unforeseeable technical problems or, if they are foreseeable, are unavoidable. Similarly, Mobidetect shall not be responsible when the defective action of the user, whether the sender or recipient of the message, causes damage.</span>
                            </p><h5 className="c6" id="h.s9566of9q1k7"><span className="c0">14. Subscription and reimbursement</span>
                        </h5>
                            <p className="c2"><span
                                className="c1">Access the service using a trial of 24 hours for USD 1 and after that time if you do not cancel the account, your subscription to the service is monthly for an amount of EUR 19 and will be renewed automatically for the same period of time until its completion.</span>
                            </p>
                            <p className="c2"><span className="c1">Unless you cancel your subscription, you authorise us to charge the monthly subscription fee corresponding to your payment method.</span>
                            </p>
                            <p className="c2"><span className="c1">You may cancel your subscription to the Service whenever you wish, and you shall continue to have access to it until the end of your monthly invoicing period. To the extent permitted by law, the payments are non-refundable and we do not give refunds or payments for partial periods of the monthly subscription.</span>
                            </p>
                            <p className="c2"><span className="c1">In any case, if you had contracted the Service, the corresponding amount had been invoiced after the initial grace period, but you had not sent any message, you may ask for the full refund of the amount charged if you request it before 14 calendar days have passed since contracting the Service.</span>
                            </p>
                            <p className="c2"><span className="c1">To cancel the Service or to request a refund, log in to your profile or contact us at: info@mobidetect.com</span>
                            </p>
                            <p className="c2"><span className="c1">If the credit card with which you registered on the Service does not have sufficient funds when charging the monthly payment, the account will be cancelled at that time.</span>
                            </p><h5 className="c6" id="h.3qr6xn5qc0dk"><span className="c0">15. Withdrawal</span></h5>
                            <p className="c2"><span className="c1">If you contract the Service, you shall be contracting the supply of digital content not provided in physical form.</span>
                            </p>
                            <p className="c2"><span className="c1">Therefore, and as soon as the content has started to be implemented, you accept and understand that the right of withdrawal, as established in article 103 m) of the General Law on Consumer and User Protection, approved by the Royal Legislative Decree 1/2007, of 16 November, is not applicable to this contract.</span>
                            </p><h5 className="c6" id="h.46hpsd9a91bh"><span className="c0">16. Modifications and nullity</span>
                        </h5>
                            <p className="c2"><span className="c1">We may update the terms and conditions of the Service in the future, as well as the characteristics and functions of the Service itself.</span>
                            </p>
                            <p className="c2"><span className="c1">We shall inform you about the changes in the terms and conditions by placing a notification in a visible place on our website and/or by email.</span>
                            </p>
                            <p className="c2"><span className="c1">If any clause included in our terms and conditions is declared, fully or partially, null or void, it shall only affect the provision in question or the part that is null or void. The rest of our terms and conditions shall remain in effect and the provision in question, or the affected part of it shall be considered to have been excluded.</span>
                            </p><h5 className="c6" id="h.k39sqlwonz7x"><span className="c0">17. Complaints and actions arising from the contract</span>
                        </h5>
                            <p className="c2"><span className="c1">This service is governed by Spanish law.</span></p>
                            <p className="c2"><span className="c1">In order to simplify the settlement of complaints through civil proceedings and to reduce costs, we do not exclude the possibility of submitting to an Equity Arbitration of the Court of Arbitration of the Chambers of Commerce and Industry.</span>
                            </p>
                            <p className="c2"><span>In this regard, and according to the applicable regulations, Mobidetect informs you of the existence of a European online platform for resolving disputes which enables these disputes to be settled out-of-court for contracts concluded online between consumers and Internet service providers. You may access this platform through the following webpage:</span><span><a
                                className="c11"
                                href="https://www.google.com/url?q=http://ec.europa.eu/odr&amp;sa=D&amp;source=editors&amp;ust=1632342419523000&amp;usg=AOvVaw0yvg62RutL1opozqg-GwyM">&nbsp;</a></span><span
                                className="c7"><a className="c11"
                                                  href="https://www.google.com/url?q=http://ec.europa.eu/odr&amp;sa=D&amp;source=editors&amp;ust=1632342419523000&amp;usg=AOvVaw0yvg62RutL1opozqg-GwyM">http://ec.europa.eu/odr</a></span>
                            </p><h5 className="c6" id="h.mryuwv9lenr7"><span className="c0">18. Contact</span></h5>
                            <p className="c2"><span className="c1">For any further clarification, incident or complaint, please contact us through:</span>
                            </p>
                            <p className="c2"><span className="c1">E-mail:: info@mobidetect.com</span></p>
                            <p className="c2 c12"><span className="c1"></span></p>
                            <p className="c10"><span className="c1"></span></p>

                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default Terms;
